import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const RenewableEnergyInConstructionIndustry: React.FC = () => {
  return (
    <>
      <SEO
        title="How renewable energy helps the construction industry?"
        description="There are many opportunities in the construction industry to save energy and reduce emissions. We tell you about them in this article"
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          Renewable energy in the construction industry
        </Typography>
        <Typography variant="body1">
          The development of green energy has given impetus to changes in the
          construction industry. All this was reflected in more updated power
          tools and materials. There have been changes in the development of
          resources that emit carbon dioxide. Indeed, according to statistics,
          to build a new house, the necessary materials, tools, and work emit
          about 55 tons of carbon dioxide into the atmosphere, thereby changing
          the Earth's climate for the worse. Garbage at construction sites also
          pollutes the environment, which is of great concern to environmental
          scientists.
        </Typography>
        <Typography variant="body1">
          After they studied why construction sites have so much pollution that
          is released into the atmosphere, the main reason was fossil fuels. It
          is on construction sites that energy is abundant for the production of
          fuel. These non-renewable energy sources leave a huge carbon footprint
          in the construction industry.
        </Typography>
        <Typography variant="h2" color="blue">
          Renewable energy reduces CO2 emissions from construction equipment
        </Typography>
        <Typography variant="body1">
          The vast amount of technology that is used in the construction of
          buildings in the UAE uses about 6 billion gallons of non-renewable
          fuel. This in turn leads to the emission of large amounts of
          greenhouse gases. Because of this, there are significant changes in
          the atmosphere.
        </Typography>
        <Typography variant="h2" color="blue">
          How does technology help renewable energy providers improve
          productivity?
        </Typography>
        <Typography variant="body1">
          To solve the problem, many construction companies have taken a course
          on the use of renewable energy sources in their work.
        </Typography>
        <Typography variant="body1">Renewable energy</Typography>
        <Typography variant="body1">
          Until recently, many companies did not even consider renewable energy
          sources. But as the environmental and commercial benefits of green
          technologies are becoming more and more obvious, companies that want
          to be competitive are starting to pay attention to the use of
          renewable energy sources. Now renewable energy sources are no longer
          as inefficient and expensive as they used to be.
        </Typography>
        <Typography variant="body1">
          Already, renewable energy has become more affordable. And construction
          companies are also smoothly moving to the use of renewable energy
          sources. All this happens because power tools, heating, lighting, and
          air conditioning consume too much energy on construction sites and
          leads to high costs. Using solar, hydro, and wind energy, construction
          companies reduce emissions, support green energy, and design buildings
          in an environmentally friendly way.
        </Typography>
        <Typography variant="body1">Solar energy</Typography>
        <Typography variant="body1">
          Solar energy is an example of the most common source of renewable
          energy. The construction industry has also found ways to use solar
          energy to power its equipment. Even though solar energy construction
          is a fairly new direction, it is already actively developing.
        </Typography>
        <Typography variant="body1">
          More recently, Volvo introduced renewable energy-powered construction
          equipment that is ideal for the construction industry. These are
          loaders and excavators and other vehicles necessary at the
          construction site. Also, solar lights are much more convenient and
          economical than their previous fossil fuel alternatives. In addition,
          they are much cheaper to operate. In this way, companies can reduce
          costs and emissions while maintaining the visibility they need.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/renewable-energy-in-construction-industry/image-1.jpg"
            alt="Technology solar energy"
            title="Technology solar energy"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          For construction companies, photovoltaic windows should also be
          considered. These are panels that absorb solar energy and turn it into
          energy. Photovoltaic windows are translucent. In this way, the
          building reduces greenhouse gas emissions by 80% compared to if fossil
          fuels were used during construction.
        </Typography>
        <Typography variant="body1">Wind energy</Typography>
        <Typography variant="body1">
          The fastest-growing source of energy in the world is wind energy. Even
          though it's not as flexible as solar, it's still good enough to help
          construction companies cut costs and reduce emissions. Of course, wind
          energy construction is not exactly a viable option for every company,
          but construction crews may well receive energy from wind energy
          construction companies that are nearby.
        </Typography>
        <Typography variant="body1">
          This energy source is available for almost all construction sites. It
          is a fairly efficient source of energy. In addition to supporting the
          owner of wind farms, you are also funding environmentally friendly
          practices in your industry.
        </Typography>
        <Typography variant="body1">
          This use of renewable energy is especially relevant in areas where the
          electricity grid is not yet connected. In order not to use gas
          generators, construction teams can enter into contracts with wind
          farms.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/renewable-energy-in-construction-industry/image-2.jpg"
            alt="Technology wind energy"
            title="Technology wind energy"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">Hydropower</Typography>
        <Typography variant="body1">
          The most widely used renewable energy source in the world is
          hydroelectric power. About 71% of green energy comes from hydropower.
          To work on a construction site, construction crews cannot install a
          temporary hydroelectric power plant, but they can still use it. To do
          this, as with wind energy, construction companies can get renewable
          energy from nearby hydroelectric power plants.
        </Typography>
        <Typography variant="body1">
          This can be a good substitute for using generators, and a
          hydroelectric power plant can also replace wind energy in places,
          where there is no wind, or it is not very strong. Most construction
          projects will reduce emissions and the industry, as a whole, will
          become more environmentally sustainable. This form of energy brings
          enormous benefits to the environment: it reduces the risk of flooding,
          as well as efficient irrigation and a stable water supply.
        </Typography>
        <Typography variant="body1">Hydrogen fuel cells</Typography>
        <Typography variant="body1">
          This renewable energy sector has huge potential in the construction
          industry. Hydrogen fuel cells are scalable and portable. This is what
          makes them ideal for mobile construction sites. Even though hydrogen
          energy is a fairly new phenomenon in the construction industry, it has
          already made great strides.
        </Typography>
        <Typography variant="body1">
          Recently, fuel cell systems have been developed for the power supply
          of construction sites. In this case, the fuel cells are placed in a
          shipping container. And after that, they can feed whatever they need.
          These elements can be used to replace gas generators. This
          significantly reduces emissions into the atmosphere.
        </Typography>
        <Typography variant="body1">
          The production of construction equipment that runs on hydrogen has
          also begun to develop. Already in 2023, it is planned to produce fuel
          cell excavators. If such equipment is used in addition to a fuel cell
          generator so emissions are eliminated on-site.
        </Typography>
        <Typography variant="h2" color="blue">
          Conclusion
        </Typography>
        <Typography variant="body1">
          Ecologists predict that in 20 years the UAE will have 80% of its
          energy from renewable sources. Green technologies in the construction
          industry give impetus to predict a more energy-efficient future.
        </Typography>
        <Typography variant="body1">
          Such a transition will undoubtedly save the construction industry
          money and reduce harmful emissions.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default RenewableEnergyInConstructionIndustry
